import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Trail, animated } from 'react-spring' 
import Helmet from 'react-helmet'
import socialImg from '../images/Dir_Main_Page_Social_Media_Thumb_01.jpg'
import Layout from '../components/layout'
import '../components/directorList.scss'

const directorsPage = ({data}) => {
  return (
    <Layout>
      <Helmet>
        <title>Picture North | Director Roster</title>
        <meta property="og:title" content='Picture North | Director Roster' />
        <meta property="og:description" content='Curated talent from across the globe that creates original content and commercials. Our multilingual directors are supported by a vast network of production and post-production resources. We know how to navigate borders, cross oceans and summit mountains to tell unique and beautiful stories.' />
        <meta property="og:image" content={data.site.siteMetadata.siteUrl+socialImg} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={'picture_north'} />
        <meta name="twitter:title" content='Picture North | Director Roster' />
        <meta name="twitter:description" content='Curated talent from across the globe that creates original content and commercials. Our multilingual directors are supported by a vast network of production and post-production resources. We know how to navigate borders, cross oceans and summit mountains to tell unique and beautiful stories.' />
        <meta name="twitter:image" content={data.site.siteMetadata.siteUrl+socialImg} />
      </Helmet>
      <div className='directors-page-header text-center'>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Directors</h1>
              <p>We curate talent from across the globe to create original content and commercials. Our multilingual directors are supported by a vast network of production and post-production resources. We know how to navigate borders, cross oceans and summit mountains to tell unique and beautiful stories.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="director-list">
        <Trail
            keys={data.directors.edges.map( ({node}) => node.frontmatter.title )}
            from={{opacity:0 }}
            to={{ opacity: 1 }}
        >
          {data.directors.edges.map( ({node}, i) => props => (
            <div style={props} key={node.id} className='director-listing'>
              <Link className='dir-image-wrap' to={node.fields.slug} >
                <div className='director-image' style={{backgroundImage: `url(${node.frontmatter.cover.childImageSharp.fluid.src})`}} ></div>
              </Link>
              <div className="dir-info">
                <Link to={node.fields.slug} >
                  <h2>{node.frontmatter.title}</h2>
                </Link>
                <div className='expertise'><span>{node.frontmatter.expertise}</span></div>
              </div>
            </div>
          ))}
        </Trail>
      </div>
    </Layout>
  )
} 
export default directorsPage

export const directorsQuery = graphql`
  query {
    site {
      siteMetadata {
          siteUrl
      }
    }
    directors: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/directors/"}},sort: { order: ASC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
              slug
          }
          frontmatter {
            title
            subTitle
            expertise
            expObj
            cover {
              childImageSharp{
                fluid(maxWidth : 2000){
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`